<template>
  <div class="page-bookreader">
    <div class="page-top">
      <div class="btn-back fx-vc" v-show="showMenu" @click="pageBack">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          fill="none"
          version="1.1"
          width="9.999999046325684"
          height="17.99997329711914"
          viewBox="0 0 9.999999046325684 17.99997329711914"
        >
          <g>
            <path
              d="M8.29289,17.7071C8.68342,18.0976,9.31658,18.0976,9.70711,17.7071C10.0976,17.3166,10.0976,16.6834,9.70711,16.2929C9.70711,16.2929,8.29289,17.7071,8.29289,17.7071C8.29289,17.7071,8.29289,17.7071,8.29289,17.7071ZM0.999999,9C0.999999,9,0.292892,8.29289,0.292892,8.29289C-0.0976307,8.68342,-0.0976307,9.31658,0.292892,9.70711C0.292892,9.70711,0.999999,9,0.999999,9C0.999999,9,0.999999,9,0.999999,9ZM9.70711,1.70711C10.0976,1.31658,10.0976,0.683416,9.70711,0.292892C9.31658,-0.0976307,8.68342,-0.0976307,8.29289,0.292892C8.29289,0.292892,9.70711,1.70711,9.70711,1.70711C9.70711,1.70711,9.70711,1.70711,9.70711,1.70711ZM9.70711,16.2929C9.70711,16.2929,1.70711,8.29289,1.70711,8.29289C1.70711,8.29289,0.292892,9.70711,0.292892,9.70711C0.292892,9.70711,8.29289,17.7071,8.29289,17.7071C8.29289,17.7071,9.70711,16.2929,9.70711,16.2929C9.70711,16.2929,9.70711,16.2929,9.70711,16.2929ZM1.70711,9.70711C1.70711,9.70711,9.70711,1.70711,9.70711,1.70711C9.70711,1.70711,8.29289,0.292892,8.29289,0.292892C8.29289,0.292892,0.292892,8.29289,0.292892,8.29289C0.292892,8.29289,1.70711,9.70711,1.70711,9.70711C1.70711,9.70711,1.70711,9.70711,1.70711,9.70711Z"
              fill="#FFFFFF"
              fill-opacity="1"
            />
          </g>
        </svg>
      </div>
      <div class="chapter">
        <div class="progress">
          {{ curChapterId + 1 }}
          /
          {{ bookData.chapter.chapter_list.length }}
        </div>
        <div class="name">
          {{ curChapterName }}
        </div>
      </div>
    </div>
    <div class="book-con" @click="showMenu = !showMenu">
      <div
        class="one-chapter"
        v-for="item in bookContent"
        :key="item.id"
        :data-id="item.id"
      >
        <div class="chapter-title">
          {{ item.title }}
        </div>
        <div class="chapter-txt" v-html="item.txt"></div>
      </div>
      <div class="load-more fx-vc" ref="nextDom">loading...</div>
    </div>
    <transition name="slideUp">
      <div class="book-act fx-vb" v-if="showMenu">
        <div class="one-act chapter fx-vc" @click="showChapter = true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="20.447355270385742"
            height="18.499910354614258"
            viewBox="0 0 20.447355270385742 18.499910354614258"
          >
            <g>
              <path
                d="M1.46053,0C0.6539,0,0,0.6539,0,1.46053C0,2.26715,0.6539,2.92105,1.46053,2.92105C2.26715,2.92105,2.92105,2.26715,2.92105,1.46053C2.92105,0.6539,2.26715,0,1.46053,0ZM5.84209,0.486816C5.30434,0.486816,4.86841,0.92275,4.86841,1.4605C4.86841,1.99825,5.30434,2.43418,5.84209,2.43418L19.4737,2.43418C20.0114,2.43418,20.4474,1.99825,20.4474,1.4605C20.4474,0.92275,20.0114,0.486816,19.4737,0.486816L5.84209,0.486816ZM1.46053,7.78955C0.6539,7.78955,0,8.44345,0,9.25008C0,10.0567,0.6539,10.7106,1.46053,10.7106C2.26715,10.7106,2.92105,10.0567,2.92105,9.25008C2.92105,8.44345,2.26715,7.78955,1.46053,7.78955ZM5.84209,8.27637C5.30434,8.27637,4.86841,8.7123,4.86841,9.25005C4.86841,9.7878,5.30434,10.2237,5.84209,10.2237L19.4737,10.2237C20.0114,10.2237,20.4474,9.7878,20.4474,9.25005C20.4474,8.7123,20.0114,8.27637,19.4737,8.27637L5.84209,8.27637ZM1.46053,15.5789C0.6539,15.5789,0,16.2328,0,17.0394C0,17.846,0.6539,18.4999,1.46053,18.4999C2.26715,18.4999,2.92105,17.846,2.92105,17.0394C2.92105,16.2328,2.26715,15.5789,1.46053,15.5789ZM5.84209,16.0657C5.30434,16.0657,4.86841,16.5016,4.86841,17.0394C4.86841,17.5771,5.30434,18.013,5.84209,18.013L19.4737,18.013C20.0114,18.013,20.4474,17.5771,20.4474,17.0394C20.4474,16.5016,20.0114,16.0657,19.4737,16.0657L5.84209,16.0657Z"
                fill-rule="evenodd"
                fill="#FFFFFF"
                fill-opacity="1"
              />
            </g>
          </svg>
        </div>
        <div
          class="one-act shelf fx-vc"
          @click="addToShelf"
          v-if="!bookData.is_fav"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="14.498779296875"
            height="16.000244140625"
            viewBox="0 0 14.498779296875 16.000244140625"
          >
            <g>
              <path
                d="M0,14L0,2C0,0.89543,0.89543,0,2,0L11,0C12.1046,0,13,0.89543,13,2L13,7.99976L11.5,7.99976L11.5,2Q11.5,1.5,11,1.5L9.9989,1.5L9.9989,6.13021C9.9989,6.51611,9.58029,6.75654,9.24696,6.5621L8.25084,5.98103C8.09515,5.89021,7.90265,5.89021,7.74696,5.98103L6.75084,6.5621C6.41751,6.75654,5.9989,6.51611,5.9989,6.13021L5.9989,1.5L2,1.5Q1.5,1.5,1.5,2L1.5,14Q1.5,14.5,2,14.5L6.50037,14.5L6.50037,16L2,16C0.89543,16,0,15.1046,0,14ZM8.9989,1.5L6.9989,1.5L6.9989,5.2597L7.24309,5.11726Q7.59338,4.91292,7.9989,4.91292Q8.40442,4.91292,8.75471,5.11725L8.9989,5.2597L8.9989,1.5ZM10.4989,15.2502L10.4989,13.5005L8.74878,13.5005C8.33457,13.5005,7.99878,13.1647,7.99878,12.7505C7.99878,12.3363,8.33457,12.0005,8.74878,12.0005L10.4989,12.0005L10.4989,10.2502C10.4989,9.83603,10.8347,9.50024,11.2489,9.50024C11.6631,9.50024,11.9989,9.83603,11.9989,10.2502L11.9989,12.0005L13.7488,12.0005C14.163,12.0005,14.4988,12.3363,14.4988,12.7505C14.4988,13.1647,14.163,13.5005,13.7488,13.5005L11.9989,13.5005L11.9989,15.2502C11.9989,15.6645,11.6631,16.0002,11.2489,16.0002C10.8347,16.0002,10.4989,15.6645,10.4989,15.2502Z"
                fill-rule="evenodd"
                fill="#FFFFFF"
                fill-opacity="1"
              />
            </g>
          </svg>
          Add to Library
        </div>
        <div class="one-act shelf fx-vc faved" v-else>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            fill="none"
            version="1.1"
            width="15.845444679260254"
            height="15.999999046325684"
            viewBox="0 0 15.845444679260254 15.999999046325684"
          >
            <g>
              <path
                d="M0,2L0,14C0,15.1046,0.89543,16,2,16L6.50037,16L6.50037,14.5L2,14.5Q1.5,14.5,1.5,14L1.5,2Q1.5,1.5,2,1.5L5.9989,1.5L5.9989,6.13021C5.9989,6.51611,6.41751,6.75654,6.75084,6.5621L7.74696,5.98103C7.90265,5.89021,8.09515,5.89021,8.25084,5.98103L9.24696,6.5621C9.58029,6.75654,9.9989,6.51611,9.9989,6.13021L9.9989,1.5L11,1.5Q11.5,1.5,11.5,2L11.5,7.99976L13,7.99976L13,2C13,0.89543,12.1046,0,11,0L2,0C0.89543,0,0,0.89543,0,2ZM6.9989,1.5L8.9989,1.5L8.9989,5.2597L8.75471,5.11725Q8.40442,4.91292,7.9989,4.91292Q7.59338,4.91292,7.24309,5.11726L6.9989,5.2597L6.9989,1.5ZM9.23283,15.5008C9.41331,15.6739,9.64897,15.7609,9.88555,15.7609C10.1212,15.7609,10.3578,15.6739,10.5383,15.5008L13.0568,12.5091L15.5754,9.51731C15.9355,9.17026,15.9355,8.608,15.5754,8.26096C15.2145,7.91301,14.63,7.91301,14.27,8.26096L9.88555,13.6149L7.57544,11.0039C7.21541,10.6569,6.63098,10.6569,6.27002,11.0039C5.90999,11.351,5.90999,11.9139,6.27002,12.261L9.23283,15.5008Z"
                fill-rule="evenodd"
                fill="#FFFFFF"
                fill-opacity="1"
                style="mix-blend-mode: passthrough"
              />
            </g>
          </svg>
          In The Library
        </div>
      </div>
    </transition>

    <transition name="slideLeft">
      <div
        class="chapter-popup"
        v-if="showChapter"
        @click="showChapter = false"
      >
        <div class="popup-con" @click.stop>
          <div class="book-detail fx-vc">
            <div class="cover">
              <img
                loading="lazy"
                :src="`https://static.novellettes.com/${bookData.avatar}`"
                alt=""
              />
            </div>
            <div class="detail">
              <div class="name">{{ bookData.name }}</div>
              <div class="auther">
                {{ bookData.author }}
              </div>
            </div>
          </div>
          <div class="total-chapter">
            {{ bookData.chapter.chapter_list.length }} chapters
          </div>
          <div class="chapter-list" ref="chapterList">
            <div
              class="ochapter fx-vc"
              v-for="item of bookData.chapter.chapter_list"
              :key="item.chapter_id"
              :class="{ cur: item.chapter_id === curChapterId }"
              @click="toChapterPos(item)"
            >
              <div class="txt">
                {{ item.chapter_name }}
              </div>
              <div class="icon" v-if="item.require_vip && !userData.is_vip">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  fill="none"
                  version="1.1"
                  width="10.666666984558105"
                  height="15.199870109558105"
                  viewBox="0 0 10.666666984558105 15.199870109558105"
                >
                  <g>
                    <path
                      d="M2.22266,3C2.22266,1.34315,3.61555,0,5.33377,0C7.05199,0,8.44488,1.34315,8.44488,3L8.44488,4.5332L8.53333,4.5332C9.71154,4.5332,10.6667,5.48833,10.6667,6.66654L10.6667,13.0665C10.6667,14.2447,9.71154,15.1999,8.53333,15.1999L2.13333,15.1999C0.955126,15.1999,0,14.2447,0,13.0665L0,6.66654C0,5.48833,0.955126,4.5332,2.13333,4.5332L2.22266,4.5332L2.22266,3ZM7.29258,2.99997L7.29258,4.5332L3.37488,4.5332L3.37488,2.99997C3.37488,1.95677,4.25188,1.11108,5.33373,1.11108C6.41557,1.11108,7.29258,1.95677,7.29258,2.99997ZM6.66667,10.6666C6.66667,10.1904,6.41268,9.7503,6.00033,9.51207L6.00033,8.4445C6.00033,8.07631,5.70185,7.77783,5.33366,7.77783C4.96547,7.77783,4.66699,8.07631,4.66699,8.4445L4.66699,9.5117C4.25427,9.74982,4,10.1901,4,10.6666C4,11.403,4.59695,11.9999,5.33333,11.9999C6.06971,11.9999,6.66667,11.403,6.66667,10.6666Z"
                      fill-rule="evenodd"
                      fill="#FFFFFF"
                      fill-opacity="0.4000000059604645"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <transition name="slideUp">
      <div
        class="unlock-vip"
        @click="showMenu = !showMenu"
        v-if="showCoinsPop && !userData.is_vip"
      >
        <!-- <coins-box></coins-box> -->
        <component :is="AsyncCoinsBox"></component>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  apiGetBookDetail,
  apiAddBookShelf,
  apiGetChapterList,
  reportFBInfo,
  reportFBInfo2,
  checkPayStatus,
  apiGetUserInfoByUUID,
} from "@/api";
import debounce from "lodash/debounce";
// import CoinsBox from "@/components/coinsList";
import Cookies from "js-cookie";
import { Toast } from "vant";

export default {
  name: "BookReader",
  data() {
    return {
      bookId: 0,
      showMenu: false,
      showChapter: false,
      bookData: {
        chapter: {
          chapter_list: [],
        },
      },
      curChapterId: 0,
      bookContent: {},
      pauseScroll: false,
      showCoinsPop: false,
      pageout: false,
      loadChapter: false,
      loadOnce:false,
      showSuccess:false,
      ta:null,
      AsyncCoinsBox:null,
    };
  },
  watch: {
    curChapterId: {
      handler(v) {
        localStorage.setItem(`bookChapter${this.bookId}`, v);
      },
      immediate: true,
    },
    showCoinsPop(n) {
      if (n) {
        this.$nextTick(() => {
          document.body.style.overflow = "hidden";
        });
      } else {
        document.body.style.overflow = "auto";
      }
    },
    showChapter(n) {
      if (n) {
        document.body.style.overflow = "hidden";
        this.$nextTick(() => {
          const dom = this.$refs.chapterList;
          const pos = dom.querySelector(".cur").getBoundingClientRect();
          console.log(pos);
          dom.scrollTo({ top: pos.top - document.body.clientHeight / 2 });
        });
      } else {
        document.body.style.overflow = "auto";
      }
    },
    userData() {},
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      userData: (state) => state.userData,
    }),
    curChapterName() {
      const curC = this.bookData.chapter.chapter_list[this.curChapterId];
      if (!curC) return "";
      return curC.chapter_name;
    },
  },
  methods: {
    loadComment(){
      import(/* webpackChunkName: "CoinBox" */ '@/components/coinsList')
        .then(module => {
          this.AsyncCoinsBox = module.default;
        });
    },
    pageBack() {
      this.$router.push({ path: "/" });
    },
    async getBookChapter(chapterId) {
      this.loadChapter = true;
      this.$toast.loading({
        duration: 0,
      });

      try {
        const { code, data } = await apiGetChapterList({
          bookId: this.bookId,
          chapterId: chapterId,
        });
        this.$toast.clear();
        this.loadChapter = false;
        if (code) {
          if (code === 410) {
            this.showChapter = false;
            this.showCoinsPop = true;
            this.showMenu = true;
          }
          return;
        }

        this.pushBookContent(data);

        const cc = this.bookData.chapter.chapter_list.filter(
          (item) => item.chapter_id === chapterId
        )[0];
        if (cc.require_vip) {
          this.showChapter = false;
          console.log("userData is VIP", this.userData.is_vip);
          console.log("userData is VIP", this.userData);
          if (!this.userData.is_vip) {
            this.showCoinsPop = true;
          }

          this.showMenu = false; //true;
        }
      } catch (error) {
        if (error.status === 401) {
          Cookies.remove("atoken");
          Cookies.remove("rtoken");
          Cookies.remove("uid");
          this.$store.commit("setIsLogin", false);
          this.$store.commit("setShowLogin", true);
        }
      }
      this.intervalCheckPayStatus();
    },
    async getBookData() {
      const bookD = sessionStorage.getItem(`bookDetail${this.bookId}`);
      if(!this.loadOnce){
        setTimeout(() => {
          reportFBInfo2(); // TODO  toggle
        }, 0);
      }else{
        this.loadOnce = true;
      }
      if (bookD) {
        const pbookD = JSON.parse(bookD);
        this.bookData = pbookD;
        return Promise.resolve();
      }
      const { code, data } = await apiGetBookDetail({
        id: this.bookId,
      });
      if (code) return;
      this.bookData = data;
      return true;
    },

    chapterTxtC(txt) {
      let realTxt = txt.replace(/\n\t \n\t/g, "");
      realTxt = txt.replace(/\n\n/g, "");
      realTxt = realTxt.replace(/\n/g, "<br><br>");
      realTxt = realTxt.replace(/<br><br>\s+<br><br>/g, "<br><br>");
      return realTxt;
    },
    pushBookContent(chapterData) {
      const od = JSON.parse(JSON.stringify(this.bookContent));
      od[chapterData.chapter_id] = {
        title: chapterData.chapter_name,
        txt: this.chapterTxtC(chapterData.content),
        id: chapterData.chapter_id,
        free: !chapterData.require_vip,
      };
      this.bookContent = od;
    },
    addScrollEvent() {
      const scrollHandler = debounce(() => {
        if (this.pauseScroll) {
          this.pauseScroll = false;
          return;
        }
        if (this.pageout) {
          return;
        }

        if (this.loadChapter) {
          return;
        }

        const nextD = this.$refs.nextDom;

        // 加载上一篇
        if (window.scrollY === 0) {
          this.loadPrvChapter();
          return;
        }

        const nextPos = nextD.getBoundingClientRect();
        if (nextPos.top - 130 < document.body.clientHeight) {
          this.loadNextChatper();
          return;
        }

        const chapterDoms = document.querySelectorAll(".one-chapter");
        if (!chapterDoms || !chapterDoms.length) return;
        let curChapterDom = null;
        chapterDoms.forEach((oneChapter) => {
          const { top, bottom } = oneChapter.getBoundingClientRect();
          if (
            top < document.body.clientHeight ||
            bottom < document.body.clientHeight / 2
          ) {
            curChapterDom = oneChapter;
          }
        });
        if (!curChapterDom) return;
        this.curChapterId = +curChapterDom.dataset.id;
      }, 200);
      window.addEventListener("scroll", scrollHandler);
    },
    async toChapterPos(cData) {
      this.showCoinsPop = false;

      const id = cData.chapter_id;
      this.showChapter = false;
      this.showMenu = false;
      this.curChapterId = +id;
      this.bookContent = {};
      this.toChapter(this.curChapterId);
    },

    async toChapter(chapterId) {
      await this.getBookChapter(chapterId);
      this.$nextTick(() => {
        this.pauseScroll = true;
        window.scrollTo({ top: 20 });
      });
      return true;
    },
    async addToShelf() {
      if (this.bookData.is_fav) return;
      if (!this.isLogin) {
        this.$store.commit("setShowLogin", true);
        return;
      }
      this.$toast.loading({
        duration: 0,
      });
      const { code, data } = await apiAddBookShelf({
        book_id: this.bookData.id,
        book_name: this.bookData.name,
        avatar: this.bookData.avatar,
      });
      this.bookData.is_fav = true;
      this.$toast.success("The book has been added to the bookshelf");
      console.log(code, data);
    },
    async loadPrvChapter() {
      const cpDom = document.querySelectorAll(".one-chapter")[0];
      this.curChapterId = +cpDom.dataset.id;
      if (this.curChapterId === 0) return;
      this.curChapterId -= 1;
      await this.getBookChapter(this.curChapterId);
      this.$nextTick(() => {
        this.pauseScroll = true;
        window.scrollTo({ top: 20 });
      });
    },
    loadNextChatper() {
      const cpDoms = document.querySelectorAll(".one-chapter");
      const cpDom = cpDoms[cpDoms.length - 1];
      this.curChapterId = +cpDom.dataset.id;

      if (this.curChapterId < this.bookData.chapter.chapter_list.length - 1) {
        this.curChapterId += 1;
        this.getBookChapter(this.curChapterId);
      }
      // if(this.curChapterId )
    },
    intervalCheckPayStatus(){
      // TODO is_succ=1 
      if(checkPayStatus() === "1" && !this.showSuccess){
      this.ta = Toast.loading({
          message: '',
          forbidClick: true,
          overlay: true,
          duration: 0,
        });
        this.showSuccess = true;
        var first = 0;
        let intervalID = setInterval(() => {
          apiGetUserInfoByUUID().then(res => {
            if(res.code == 0 ){
              if(res.data.is_vip){
                this.$store.commit("setUserData", res.data);
                clearInterval(intervalID);
                this.ta.clear();
              }
              this.$store.commit("setUserData", res.data);
            }
            if(first > 5){
              clearInterval(intervalID);
              this.ta.clear();
            }
            console.log("res :",res)
            first++
          }).catch(e=>{
            if(first > 5){
              clearInterval(intervalID);
              this.ta.clear();
            }
            first++
            console.log(e)
          })
        }, 2000);  
      }
    }

  },
  beforeRouteLeave(to, from, next) {
    this.pauseScroll = true;
    this.pageout = true;
    next();
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  beforeRouteEnter(to, from, next) {
    setTimeout(() => {
      reportFBInfo(); // TODO  toggle
    }, 0);
    next();
  },
  async mounted() {
    const bookId = this.$route.params.id;
    this.bookId = bookId;
    const getcurChapterId =
      localStorage.getItem(`bookChapter${this.bookId}`) || 0;
    this.curChapterId = +getcurChapterId;
    this.addScrollEvent();
    // await this.getBookData();
    // this.toChapter(this.curChapterId);
    setTimeout(() => {
      Promise.all(this.getBookData(), this.toChapter(this.curChapterId)).then(ress=>{
      console.log("ress:",ress);
    }).catch((e) => {
      console.log("error", e);
    });  
    }, 0);
    this.loadComment();
  },
};
</script>

<style lang="less">
@import url("./index.less");
</style>
